$main: #2991ce;
$mainHover: #115293;
$mainBlack: #212529;
$mainGray: #bbb;
$mainDarkGray: #405263;
$mainRed: #e8170d;
$mainGreen: #4CBB17;

$linkColor: #1890ff;
$linkHover: #40a9ff;

$closeBtnHover: #f44336;



$mainBlackOpacity80: rgba(33, 37, 41, .8);
$modalBackdropOpacity50: rgba(0, 0, 0, .5);

$standardOpacity: all .3s ease-out;