.loginForm {
    width: 100%;
    margin-top: 10px;

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__imageWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    &__image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        color: $mainBlack;
    }

    &__subTitle {
        font-size: 18px;
        font-weight: 500;
        color: $mainBlack;
        margin-top: 5px;
    }

    &__inputRow {
        position: relative;
        margin-top: 15px;
    }

    &__label {
        position: absolute;
        color: $mainGray;
        cursor: text;
        transform: translate(14px, 18px) scale(1);
    }

    &__input {
        width: 100%;
        font-size: 16px;
        border: 1px solid $mainGray;
        border-radius: 4px;
        box-shadow: none;
        padding: 16px 14px;
        margin-bottom: 2px;
        outline-color: $main;
        transition: $standardOpacity;

        &:hover, &:focus {
            border-color: $main;
        }

        &.error {
            outline-color: red;
            border-color: red;
        }
    }

    &__errorLine {
        color: $mainRed;
        font-size: 14px;
    }

    &__submitBtn {
        width: 100%;
        color: #fff;
        background: $main;
        border: none;
        border-radius: 4px;
        padding: 14px 10px;
        margin: 24px 0 16px;
        cursor: pointer;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        transition: $standardOpacity;

        &:hover, &:focus {
            background: #115293;
        }
    }
}