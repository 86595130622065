.heading {
    display: flex;
    align-items: center;
    padding: 30px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid $mainGray;

    &__image {
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        color: $mainBlack;
    }
}