.search {
	&__label {
		position: relative;
	}

	&__input {
		width: 300px;
		font-size: 13px;
		border: 1px solid #949599;
		padding: 10px 40px 10px 15px;
		outline: none;
		transition: $standardOpacity;

		&:focus {
			border: 1px solid $mainHover;
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		right: 10px;
		width: 15px;
		height: 15px;
		fill: #949599;
		transform: translate(0, -50%);
		cursor: text;

		path {
			stroke: #949599;
		}
	}
}