.mainTemplate {
    &__container {
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1500px;

        &_centered {
            max-width: 500px;
            margin-top: 60px;
        }
    }
}