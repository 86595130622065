.customInvoiceForm {
	max-width: 900px;
	margin: 15px auto 0;

	&__body {
		background: #fafafa;
	}

	&__item {
		padding: 15px 20px;

		&:not(:last-child) {
			border-bottom: 1px solid #efefef;
		}
	}

	&__inputContainer {
		display: grid;
		grid-template-columns: 1fr 30px;
		grid-gap: 15px;
		align-items: center;
		margin-bottom: 10px;

		&_services {
			grid-template-columns: repeat(3, 1fr) 30px;
		}
	}

	&__label {
		font-size: 14px;
		color: $mainBlack;
		text-transform: uppercase;
	}

	&__input {
		padding: 8px 11px;
	}

	&__btnWrapper {
		display: flex;
		justify-content: center;
		padding: 20px 0;
	}

	&__removeBtn {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: none;
		border: none;
		padding: 0;
		margin: 0;
		outline: none;
		cursor: pointer;

		&:hover, &:focus {
			svg {
				fill: $closeBtnHover;
			}
		}

		svg {
			width: 20px;
			height: 20px;
			fill: $mainRed;
			transition: $standardOpacity;
		}
	}
}