.instances {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	}

	&__btnsWrapper {
		a {
			margin-left: 10px;
		}
	}
}

.instancesTable {
	width: 100%;
	border-collapse: collapse;
	font-size: 14px;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 10px #cdd8df;
	margin-bottom: 50px;

	&__headerElement {
		font-size: 13px;
		font-weight: 600;
		color: $mainBlack;
		background: #fafafa;
		border-bottom: 1px solid #f0f0f0;
		text-align: left;
		padding: 16px;

		&_clickable {
			cursor: pointer;
			outline: none;
			transition: background .3s ease;

			&:hover {
				background: #f2f2f2;
			}

			&.asc {
				.sortIcon {
					&:first-child {
						fill: $linkColor;
					}
				}
			}

			&.desc {
				.sortIcon {
					&:last-child {
						fill: $linkColor;
					}
				}
			}
		}
	}

	&__body {
		tr {
			td {
				padding: 16px;
				border-bottom: 1px solid #f0f0f0;
				transition: background .3s linear;

				a {
					color: $linkColor;

					&:hover, &:focus {
						color: $linkHover;
					}
				}

				&:last-child {
					min-width: 90px;
				}
			}

			&:hover {
				td {
					background: #efefef;
				}
			}
		}
	}

	&__headerElementsWrapper {
		display: inline-flex;
		align-items: center;
		font-size: 13px;
		font-weight: 700;
	}

	&__status {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;

		&_enabled {
			background: #00c600;
			box-shadow: #03bf00 0 -1px 3px 1px, inset #19c10e 0 -1px 9px, #7D0 0 2px 12px;
		}
	}

	&__button {
		display: inline-block;
		background: none;
		border: none;
		padding: 0;
		margin: 0 5px;
		outline: none;
		cursor: pointer;

		svg {
			width: 17px;
			height: 17px;
			fill: rgba(0, 0, 0, .4);
			transition: $standardOpacity;
		}

		&_delete {
			&:hover {
				svg {
					fill: rgba(255, 0, 0, 0.8);
				}
			}
		}
	}

	&__billingTypeImg {
		width: 30px;
		height: 30px;
	}
}