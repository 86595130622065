.errorPage {
    margin: 50px auto 0;
    text-align: center;

    &__link {
        display: inline-block;
        color: #fff;
        background: $main;
        border: none;
        border-radius: 4px;
        padding: 14px 40px;
        margin: 40px 0 20px;
        text-decoration: none;
        cursor: pointer;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        transition: $standardOpacity;

        &:hover, &:focus {
            background: #115293;
        }
    }

    &__img {
        max-width: 600px;
        width: 100%;
        margin-bottom: 30px;
    }
}