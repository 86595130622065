.input {
	width: 100%;
	color: $mainBlackOpacity80;
	background: #fff;
	border-radius: 2px;
	border: 1px solid #d9d9d9;
	padding: 4px 11px;
	outline: none;
	transition: $standardOpacity;

	&:hover, &:focus {
		border-color: $linkHover;
	}

	&__label {
		display: inline-block;
		padding-bottom: 10px;
	}

	&__error {

	}
}