.card {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 10px #cdd8df;
	margin-bottom: 30px;

	&__header {
		font-size: 20px;
		color: $mainBlack;
		padding: 15px 20px;
		background-color: #fff;
		border-radius: 4px 4px 0 0;
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
		background: #fafafa;
		border-top: 1px solid #efefef;
		border-left: 1px solid #efefef;
	}

	&__gridItem {
		position: relative;
		padding: 24px;
		border-bottom: 1px solid #efefef;
		border-right: 1px solid #efefef;
	}

	&__itemTitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		color: $mainBlack;
		text-transform: uppercase;
		word-break: break-word;

		&.editable {
			cursor: pointer;

			&:hover {
				.card {
					&__itemTitleIcon {
						fill: rgba(0, 0, 0, .8);

						&_cancel {
							path {
								stroke: $closeBtnHover;
							}
						}
					}
				}
			}
		}
	}

	&__info {
		position: absolute;
		top: 5px;
		left: 5px;
		font-size: 12px;
		font-style: italic;
		font-weight: bold;
		color:  #969696;
		border: 1px solid #969696;
		border-radius: 50%;
		padding: 0 5px;
		cursor: pointer;
	}

	&__itemTitleIcon {
		min-width: 14px;
		width: 14px;
		height: 14px;
		fill: rgba(0, 0, 0, .4);
		margin-left: 5px;
		transition: $standardOpacity;

		path {
			stroke: rgba(0, 0, 0, .4);
			transition: $standardOpacity;
		}
	}

	&__itemBody {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 500;
		color: $mainBlack;
		margin-top: 24px;
		word-break: break-word;

		svg {
			margin-right: 10px;
		}

		&_editable {
			margin-top: 16px;
		}
	}

	&__editableForm {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__submitBtn {
		height: 30px;
		background: none;
		border: none;
		padding: 0 0 0 13px;
		outline: none;
		cursor: pointer;

		&:hover {
			svg {
				fill: $linkHover;
			}
		}
	}

	&__submitIcon {
		width: 16px;
		height: 16px;
		fill: $linkColor;
		margin-right: 0 !important;
		cursor: pointer;
		transition: $standardOpacity;
	}

	&__externalId {
		display: flex;
	}

	&__idIcon {
		border: 1px solid #51D88A;
		color: #51D88A;
		border-radius: 50%;
		padding: 2px;

	}
}