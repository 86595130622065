.checkbox {
	position: relative;
	display: inline-block;
	cursor: pointer;
	vertical-align: top;

	&_checked {
		.checkbox {
			&__inner {
				background: $linkColor;
				border-color: $linkColor;

				&:after {
					content: " ";
					display: table;
					border: 2px solid #fff;
					border-top: 0;
					border-left: 0;
					transform: rotate(45deg) scale(1) translate(-50%,-50%);
					opacity: 1;
					transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
				}
			}
		}
	}

	&__label {
		color: $mainBlackOpacity80;
		cursor: pointer;

		&:hover {
			.checkbox {
				&__inner {
					border-color: $linkColor;
				}
			}
		}
	}

	&__input {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
	}

	&__inner {
		position: relative;
		display: block;
		width: 18px;
		height: 18px;
		background: #fff;
		border: 1px solid #d9d9d9;
		transition: $standardOpacity;

		&:after {
			content: " ";
			position: absolute;
			top: 50%;
			left: 22%;
			width: 4px;
			height: 8px;
			border: 2px solid #fff;
			border-top: 0;
			border-left: 0;
			transform: rotate(45deg) scale(0) translate(-50%, -50%);
			transition: all .1s cubic-bezier(.7, -.5, .9, .6), opacity .1s;
		}
	}

	&__text {
		padding: 0 8px;
	}
}