body.modal-is-open {
	overflow: hidden;
}

.modal-is-open {
	.modal {
		overflow-x: hidden;
		overflow-y: auto;

		&__overlay {
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
}

.modal {
	z-index: 1000;

	&-lg {
		width: 800px;
	}

	&-md {
		width: 500px;
	}

	&-sm {
		width: 300px;
	}

	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $modalBackdropOpacity50;

		&:before,
		&:after {
			content: '';
			flex: 1;
		}
	}

	&__container {
		margin: 50px auto;
		outline: none;
	}

	&-content {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: #fff;
		border-radius: 4px;
		outline: 0;
		overflow: auto;
	}

	&-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 16px;
		border-bottom: 1px solid #e9ecef;
	}

	&-title {
		font-size: 20px;
		font-weight: 500;
		color: $mainBlack;
	}

	&__closeBtn {
		float: right;
		font-size: 24px;
		font-weight: 700;
		color: $mainBlack;
		background: none;
		border: none;
		line-height: 1;
		text-shadow: 0 1px 0 #fff;
		margin-left: auto;
		opacity: .5;
		outline: none;
		cursor: pointer;
		transition: $standardOpacity;

		&:hover, &:focus {
			color: $closeBtnHover;
			opacity: 1;
		}
	}

	&-body {
		position: relative;
		flex: 1 1 auto;
		font-size: 14px;
		padding: 16px;
	}

	&-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 16px;
		border-top: 1px solid #e9ecef;

		&>:not(:last-child) {
			margin-right: 4px;
		}

		&>:not(:first-child) {
			margin-left: 4px;
		}
	}
}
