.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	background-color: $linkColor;
	border: 1px solid $linkColor;
	border-radius: 4px;
	padding: 0 15px;
	text-align: center;
	transition: all .3s cubic-bezier(.6, .05, .4, 1);
	cursor: pointer;
	outline: none;

	&:hover, &:focus {
		background-color: $linkHover;
		border-color: $linkHover;
	}

	svg {
		width: 14px;
		height: 14px;
		fill: #fff;
		margin-right: 8px;
		transition: all .3s cubic-bezier(.6, .05, .4, 1);
	}

	&_green {
		background-color: $mainGreen;
		border: 1px solid $mainGreen;

		&:hover, &:focus {
			background-color: lighten($mainGreen, 5%);
			border-color: lighten($mainGreen, 5%);
		}
	}

	&_inverse {
		color: $mainBlackOpacity80;
		background-color: #fff;
		border-color: #d9d9d9;

		&:hover, &:focus {
			color: $linkHover;
			background-color: #fff;

			svg {
				fill: $linkHover;
			}
		}

		svg {
			fill: $mainBlackOpacity80;
		}
	}

	&_full {
		width: 100%;
	}
}