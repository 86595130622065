.select {
	width: 100%;

	&:hover {
		.select {
			&Inner {
				&__control {
					border-color: $linkHover;
					box-shadow: none;
				}
			}
		}
	}

	.select {
		&Inner {
			&__control {
				min-height: auto;
				color: $mainBlackOpacity80;
				background: #fff;
				border-radius: 2px;
				border: 1px solid #d9d9d9;
				padding: 4px 11px;
				outline: none;
				transition: $standardOpacity;
				cursor: pointer;
			}

			&__single {
				&-value {
					color: $mainBlackOpacity80;
				}
			}

			&__value {
				&-container {
					padding: 0 5px 0 0;
				}
			}

			&__indicator {
				padding: 0 0 0 5px;

				&-separator {
					margin: 4px 0;
				}

				svg {
					margin-right: 0;
				}
			}

			&__menu {
				margin-top: 1px;
			}
		}
	}

	&__label {
		display: inline-block;
		padding-bottom: 10px;
	}

	&__error {

	}
}