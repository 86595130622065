.loader {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, .7);
    top: 0;
    left: 0;
    z-index: 1051;

    &__image {
        position: fixed;
        top: 50%;
        left: calc(50% - 50px);
        height: 100px;
        width: 100px;
        animation-name: rotate-logo;
        animation-iteration-count: infinite;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        transform: translate(-50%, -50%) perspective(500px) rotateY(-42deg);
    }
}

@keyframes rotate-logo {
    0% {
        transform: perspective(500px) rotateY(0);
    }
    50% {
        transform: perspective(500px) rotateY(180deg);
    }
    100% {
        transform: perspective(500px) rotateY(0);
    }
}