* {
    font-family: 'Nunito', Arial, sans-serif;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

body {
    background: #ebebeb;
}

a {
    text-decoration: none;
    transition: $standardOpacity;
}

.center {
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.circledIcon {
    width: 22px;
    height: 22px;

    &_green {
        fill: #51D88A;
    }

    &_red {
        fill: #ef5753;
    }
}

// datepicker fix
.react-datepicker-wrapper {
    width: 100%;
}