.tableSorter {
	display: inline-flex;
	align-items: center;
	flex-direction: column;

	&__wrapper {
		margin-left: 5px;
	}

	.sortIcon {
		width: 11px;
		height: 11px;
		fill: #bfbfbf;

		&:last-child {
			margin-top: -3px;
		}
	}
}