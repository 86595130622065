.instance {
	display: grid;
	grid-template-columns: 1fr 5fr;
	grid-gap: 30px;

	&__name {
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 700;
		margin: 30px 0;
	}

	&__tabList {
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin-top: 30px;
	}

	&__tab {
		margin-bottom: 15px;
	}

	&__tabLink {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 600;
		color: $mainDarkGray;
		transition: $standardOpacity;

		&:hover, &:focus {
			color: $linkHover;

			.instance {
				&__tabIcon {
					fill: $linkHover;
				}
			}
		}

		&_active {
			color: $linkHover;

			.instance {
				&__tabIcon {
					fill: $linkHover;
				}
			}
		}
	}

	&__tabIcon {
		width: 30px;
		height: 30px;
		fill: $mainDarkGray;
		margin-right: 15px;
		transition: $standardOpacity;
	}

	&__table {
		width: 100%;
		border-collapse: collapse;
		font-size: 14px;
		background: #fff;
	}

	&__tableHeadItem {
		font-size: 13px;
		font-weight: 700;
		color: $mainBlack;
		background: #fafafa;
		text-align: left;
		padding: 16px;

		&.asc {
			.sortIcon {
				&:first-child {
					fill: $linkColor;
				}
			}
		}

		&.desc {
			.sortIcon {
				&:last-child {
					fill: $linkColor;
				}
			}
		}
	}

	&__tableHeadItemWrapper {
		display: flex;
		align-items: center;
	}

	&__tableBody {
		tr {
			td {
				padding: 12px 16px;
				border-bottom: 1px solid #f0f0f0;
			}
		}
	}

	&__tableIcon {
		width: 20px;
		height: 20px;

		&_refresh {
			stroke: $linkColor;
			transition: $standardOpacity;

			&:hover {
				stroke: $linkHover;
			}
		}

		&_file {
			fill: #D22600;
			transition: $standardOpacity;
		}
	}

	&__empty {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	&__emptyText {
		font-size: 30px;
		background-color: #FFFFFF;
		border-radius: 20px;
		padding: 10px 20px;
	}
}